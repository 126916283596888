import React from 'react';

 
const footer = () => {
  return (
    <footer className=''>
         
      <div className='bottom-footer pt-3 pb-3 text-center'>
        <small>© All Right Reserved. </small>
      </div>
    </footer>
  );
};

export default footer;
