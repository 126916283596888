import React from 'react';

import Section from '../../../HOC/Section';
import icon1 from '../../../assets/img/geom.png';

import icon2 from '../../../assets/img/mangment.png';
import icon3 from '../../../assets/img/indes.png';
import icon4 from '../../../assets/img/rest.png';

const Service = () => {
  return (
    <Section id='services'>







      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>حلولنا  </span>الاستشارية
          </h3>
   
        </div>

  
 
  


  <div style={{overflowX:'auto' }} className=' container  text-center' >
    




<img src={icon1} alt="team member" class="img-responsive  "  height='50px' width='60px'/>

<h5>الاستشارات الهندسية</h5>
<br></br>

<ul dir='rtl'  style={{  display: 'grid',
  justifyContent: 'center' ,    marginLeft: '5em',
  textIndent:' 0em'}} class='custom-list' >
 
 <li style={{textAlign : 'right',fontSize : '15px'}} > المخططات و تشمل المعمارية الانشائية، الكهربائية ، الميكانيكية</li>
 <li style={{textAlign : 'right',fontSize : '15px'}} >رخص البناء</li>
 <li style={{textAlign : 'right',fontSize : '15px'}} > التصميم الداخلي</li>
 <li style={{textAlign : 'right',fontSize : '15px'}} >الاشراف الهندسي</li>
 <li style={{textAlign : 'right',fontSize : '15px'}} >ادارة المشاريع</li>
 <li style={{textAlign : 'right',fontSize : '15px'}} >استشارات الامن و السلامة</li>
 <li style={{textAlign : 'right',fontSize : '15px'}} > التصميم الحضري </li>
 <li style={{textAlign : 'right',fontSize : '15px'}} > التخطيط</li>


</ul> 
<br></br>


<img src={icon3} alt="team member" class="img-responsive"  height='50px' width='60px'/>

<h5>الاستشارات الصناعية</h5>
<br></br>

<ul dir='rtl'  style={{  display: 'grid',
  justifyContent: 'center'  ,    marginLeft: '-2em',
  textIndent:' 0em'}} class='custom-list' >
 
  <li style={{textAlign : 'right',fontSize : '15px'}} >تأهيل الشركات للحصول على الايزو في المجالات المختلفة</li>
  <li style={{textAlign : 'right',fontSize : '15px'}} >تصميم عمليات الانتاج و تصميم موقع العمل</li>
  <li style={{textAlign : 'right',fontSize : '15px'}} >  تشخيص و حل المشاكل الصناعية القائمة</li>
  <li style={{textAlign : 'right',fontSize : '15px'}} >تشخيص مشكلات اداء العمليات ووضع الحلول التنفيذية لها</li>
  <li style={{textAlign : 'right',fontSize : '15px'}} >تخطيط العمليات و تبسيط الاجراءات</li>
  <li style={{textAlign : 'right',fontSize : '15px'}} >حلول التمويل الصناعي و ذلك عن طريق : صندوق التنمية الصناعي- البنوك-المستثمرين</li>
  <li style={{textAlign : 'right',fontSize : '15px'}} >اجراء الدراسات و تحليل البيانات لتطوير بيئة العمل الصناعية</li>
   <li style={{textAlign : 'right',fontSize : '15px'}} > التأهيل و الاستشارات التشغيلية</li>
  <li style={{textAlign : 'right',fontSize : '15px'}} > تطوير خطط الانتاج و ادارة الجودة</li>
 
 
</ul>
<br></br>

<img src={icon2} alt="team member" class="img-responsive"  height='50px' width='60px'    />

<h5>الاستشارات الادارية</h5>
<br></br>

<ul dir='rtl'  style={{  display: 'grid',
  justifyContent: 'center' ,    marginLeft: '-2em',
  textIndent:' 0em'
 /* Space size limit */

  
  
  }} class='custom-list' >
  <li style={{textAlign : 'right',fontSize : '15px' }} > تصميم مؤشرات قياس الاداء</li>
  <li style={{textAlign : 'right',fontSize : '15px'}} > التخطيط الاستراتيجي واعداد خطة العمل</li>
  <li style={{textAlign : 'right',fontSize : '15px'}} > التطوير الاداري وتطوير الاعمال</li>
  <li style={{textAlign : 'right',fontSize : '15px'}} >  حوكمة الشركات</li>
  <li style={{textAlign : 'right',fontSize : '15px'}} >  عمليات اعادة الهيكلة</li>
 
 

  
  <li style={{textAlign : 'right',fontSize : '15px'}} > عمليات صناعة و تحليل القرار</li>
  <li style={{textAlign : 'right',fontSize : '15px'}} >  تصميم الخطط المتعلقة بريادة الاعمال</li>
  <li style={{textAlign : 'right',fontSize : '15px'}} > تحليل و تطوير الانظمة المتعلقة بسلاسل الامداد</li>
  <li style={{textAlign : 'right',fontSize : '15px'}} > تطوير و رفع كفاءة ادارة المواد البشرية وادارتها</li>
  <li style={{textAlign : 'right',fontSize : '15px'}} > دراسات الجدوى الاقتصادية وتشمل:الدراسات التسويقية-الدراسات الفنية-الدراسات المالية</li>
  
</ul>
<br></br>
<img src={icon4} alt="team member" class="img-responsive"  height='50px' width='60px'/>

<h5>خدمات المطاعم  <br></br>والمقاهي</h5>
<br></br>

<ul dir='rtl'  style={{  
  display: 'grid',
  justifyContent: 'center' ,    marginLeft: '5em',
  textIndent:' 0em'
 }} class='custom-list' >
 
 
 <li style={{textAlign : 'right',fontSize : '15px'}} > دراسات الجدوى</li>
 <li style={{textAlign : 'right',fontSize : '15px'}} >بناء الهوية التجارية</li>
 <li style={{textAlign : 'right',fontSize : '15px'}} >نموذج العمل التجاري</li>
 <li style={{textAlign : 'right',fontSize : '15px'}} >ادارة المشاريع</li>
 <li style={{textAlign : 'right',fontSize : '15px'}} >خطط و استراتيجيات التسويق</li>
 <li style={{textAlign : 'right',fontSize : '15px'}} > الاستشارات التشغيلية </li>
 <li style={{textAlign : 'right',fontSize : '15px'}} > حلول سلاسل الامداد</li>
 <li style={{textAlign : 'right',fontSize : '15px'}} > الاستشارات الهندسية</li>


</ul> 


</div>




 


 
 




   




 



 
 
 
        
      </div>
    </Section>
  );
};

export default Service;
