import React from 'react';

import Section from '../../../HOC/Section';
import Image01 from '../../../assets/img/saeed.png';
import Image02 from '../../../assets/img/nabeel.png';
import Image03 from '../../../assets/img/hesham.png';
import Image04 from '../../../assets/img/balbed.png';
import Image05 from '../../../assets/img/fe.png';
import Image06 from '../../../assets/img/ab.png';


import Image07 from '../../../assets/img/mu.png';
import Image08 from '../../../assets/img/ra.png';
import Image09 from '../../../assets/img/ss.png';
import Image10 from '../../../assets/img/ali.png';
import Image11 from '../../../assets/img/hamd.png';
const Advisors = () => {
  return (
    
    <Section id='Advisors'>
        
      <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>  </span>المستشارين
          </h3>
      
        </div>
 <div class="container" >
 <div class="row"dir='rtl'    style={{   justifyItems: 'center'
,  display: 'grid',
 
alignItems: 'center'}} >
                      

                      <div class="col-md-4 col-sm-4 ">
                          <div class="team-member text-center">
                              <div class="team-img">
                              <img src={Image11} alt="team member" class="img-responsive"     height='150px' width='150px'/>
                              </div>
                     
                          </div>
                          <div class="team-title text-center">
                          <div id="wrapper">  
                              <h5>م. حمد رشيد النور</h5>
                              <span>المديرالعام  </span>
                              <br></br>
                              <a href="#open-modal9">ملف التعريف</a>
                          </div>

                          <div id="open-modal9" class="modal-window">
              <div>
              <a href="#modal-close" title="Close" class="modal-close " > <h1>&times;</h1></a>
                  <div class="text-right">
                            <h5>المؤهلات العلمية</h5>
                            <ul>
                            <li>بكالوريوس في الهندسة الصناعية من جامعة الملك عبدالعزيز</li> 
                                </ul>
                           <h5>الخبرات العملية</h5>
                           <ul>
                               <li>مهندس مبيعات في مجموعة صافولا</li>
                           <li>مهندس تطوير إداري في امانةالعاصمة المقدسة</li> 
                           <li>المؤسس والمدير العام للحمد للمقاولات</li> 
                           <li> المدير العام لمجموعة الحمد</li> 

                                      </ul>
                           <h5>الخبرات الاستشارية</h5>
                           <ul>
                           <li>إدارة المخاطر </li> 
                           <li>إدارة عمليات إعادة الهيكلة</li> 
                           <li>الادارة الاستراتيجية</li> 
                           <li>ادارةالمشاريع</li> 

                                      </ul>    
                                      </div>
</div>
</div>  </div>



                      </div>
                      <div class="col-md-4 col-sm-4">
                          <div class="team-member text-center">
                              <div class="team-img">
                              <img src={Image10} alt="team member" class="img-responsive"    height='150px' width='150px'/>
                              </div>
            
                          </div>
                          <div class="team-title text-center">
                          <div id="wrapper">  
                              <h5>م. علي إبراهيم المسيدي</h5>
                              <span>المدير التنفيذي</span>
                              <br></br>
                              <a href="#open-modal8">ملف التعريف</a>
                          </div>

                          <div id="open-modal8" class="modal-window">
              <div>
              <a href="#modal-close" title="Close" class="modal-close " > <h1>&times;</h1></a>
                  <div class="text-right">
                            <h5>المؤهلات العلمية</h5>
                            <ul>
                                <li>درجة البكالوريس في الهندسة الصناعية من جامعة الملك عبدالعزيز</li> 
                                </ul>
                           <h5>الخبرات العملية</h5>
                           <ul>
                                      <li> مهندس في قسم الادارة العامة في شركة عبداللطيف جميل المحدودة</li>
                                      <li> مهندس جودة في شركة البداهة للصناعات الحديثة</li>
                                      <li> مشرف قسم النقل و الحركة في شركة سانكيو العالمية</li>
                                      <li> مدير المشاريع في مجموعة الحمد</li>

                                      </ul>
                           <h5>الخبرات الاستشارية</h5>
                           <ul>
                                      <li>مؤشر قياس الاداء</li>
                                      <li>إدارة سلاسل الامداد</li>
                                      <li>الادارة الاستراتيجية</li>
                                      <li>دراسات الجدوى</li>
                                        </ul>    
                                      </div>
</div>
</div>  </div>
                      </div>
 

                  </div>
                    <div class="row" dir='rtl'>
                 

                        <div class="col-md-4 col-sm-4">
                            <div class="team-member text-center">
                                <div class="team-img ">
                                    <img src={Image01} alt="team member" class="img-responsive "  height='150px' width='150px'/>
                                </div>
                          
                            </div>
                          <div class="team-title text-center" >
                            <div id="wrapper">         
                            <h5>د.سعيد احمد عسيري</h5>
                                <span>مستشار</span>
                           <br></br>
                           <a href="#open-modal">ملف التعريف</a>
                            </div>
 

              <div id="open-modal" class="modal-window">
                <div>
                <a href="#modal-close" title="Close" class="modal-close " >  <h1>&times;</h1></a>
                    <div class="text-right">
                              <h5>المؤهلات العلمية</h5>
                              <ul>
                                  <li>بكالوريوس في هندسة الانتاج من جامعة الملك عبدالعزيز</li>
                                  <li>  ماجستير في الهندسة الميكانيكية من جامعة ميريلاند بالولايات المتحدة الامريكية</li>
                                  <li> دكتوراه في الهندسة الميكانيكية من جامعة ميريلاند بالولايات المتحدة الامريكية </li>
                                  </ul>
                             <h5>الخبرات العملية</h5>
                             <ul>
                             <li> عضو هيئة تدريس بقسم الهندسة الميكانيكية في جامعة الملك عبدالعزيز</li>

                                        <li> رئيس مجلس ادارة مهارات للتطوير و الاستشارات وهو اول بيت خبرة بجامعة الملك عبدالعزيز</li>
                                        <li> نائب مدير مركز تطوير التعليم الجامعي في جامعة الملك عبدالعزيز</li>
                                        </ul>
                             <h5>الخبرات الاستشارية</h5>
                             <ul>
                                        <li>التخطيط الاستراتيجية</li>
                                        <li>ادارة المشاريع الاحترافية</li>
                                        <li>الادارة الاستراتيجية</li>
                                     
                                        </ul>    
    </div>
  </div>
</div>
 
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4">
                            <div class="team-member text-center">
                                <div class="team-img">
                                <img src={Image02} alt="team member" class="img-responsive"     height='150px' width='150px'/>
                                </div>
                        
                            </div>
                            <div class="team-title text-center">
                            <div id="wrapper">  
                            <h5>نبيل سلمان المير</h5>
                                <span>مستشار  </span>
                                <br></br>
                           <a href="#open-modal1">ملف التعريف</a>
                            </div>


                            <div id="open-modal1" class="modal-window">
                <div>
                <a href="#modal-close" title="Close" class="modal-close " >  <h1>&times;</h1></a>
                    <div class="text-right">
                              <h5>المؤهلات العلمية</h5>
                              <ul>
                              <li> درجة البكالوريس في ادارة الاعمال  الدولية</li>

                                   <li> درجة الماجستير MBA  في ادارة التغيير و الاستشارات الادارية</li>
                                  </ul>
                             <h5>الخبرات العملية</h5>
                             <ul>                               
                                          <li> المدير التنفيذي لقسم المشتريات في شركة سيمنز</li>
                                        <li> المدير العام لادارة تطوير الاعمال لبرنامج HR+2</li>
                                             <li> المؤسس و الرئيس التنفيذي لمؤسسة وصول العالمية</li>

                                     <li> المؤسس و الرئيس التنفيذي لمركز مهارات                                        
رواد التدريب </li>

                                        </ul>
                             <h5>الخبرات الاستشارية</h5>
                             <ul>
                                        <li>ادارة التغيير </li>
                                        <li>هيكلة الشركات</li>
                                        <li>الادارة الاستراتيجية</li>
                                        <li>المشتريات الاستراتيجية</li>
                                          </ul>    
                                        </div>
 </div>
  </div>
          </div>

                            
                        </div>






                        <div class="col-md-4 col-sm-4">
                            <div class="team-member text-center">
                                <div class="team-img">
                                <img src={Image03} alt="team member" class="img-responsive"    height='150px' width='150px'/>
                                </div>
                   
                            </div>





                            <div class="team-title text-center">
                            <div id="wrapper">  
                            <h5>د. هشام مصطفى الادريسي</h5>
                                <span>مستشار</span>
                                <br></br>
                                <a href="#open-modal2">ملف التعريف</a>
                            </div>
                            <div id="open-modal2" class="modal-window">
                <div>
                <a href="#modal-close" title="Close" class="modal-close " > <h1>  &times;</h1></a>
                    <div class="text-right">
                              <h5>المؤهلات العلمية</h5>
                              <ul>
                                  <li> بكالوريس في الهندسة الصناعية من جامعة الملك عبدالعزيز</li>
                                  <li>  ماجستير في الادارة الهندسية من جامعة كوينزلاند للتكنولوجيا في استراليا</li>
                                  <li>دكتوراه في تخصص الهندسة الصناعي من جامعة جريفيت في استراليا</li>
                                  </ul>
                             <h5>الخبرات العملية</h5>
                             <ul>
                                        <li> مساعد مدير ببرنامج اعداد المدراء في مجموعة صافولا</li>
                                     
                                        <li> رئيس قسم الهندسة الصناعية بجامعة الملك عبدالعزيز (خلال فترتين متتاليتين).</li>
                                        <li> مساعد وكيل جامعة الملك عبدالعزيز للتشغيل و الصيانة</li>
                                        <li>  استاذ مشاوك في قسم الهندسة الصناعية بجامعة الملك عبدالعزيز</li>

                                        </ul>
                             <h5>الخبرات الاستشارية</h5>
                             <ul>
                             <li>اتخاذ القرارات متعددة المعايير </li>
                                                                            <li>الادارة الاستراتيجية</li>   
                                                                             <li>ادارة الموارد البشرية</li>

                                        <li>ادارة المشاريع </li>
                                         </ul>    
                                        </div>
 </div>
  </div>

                            </div>
                        </div>

                    </div>



                    <div class="row"dir='rtl'>
                   

                        <div class="col-md-4 col-sm-4">
                            <div class="team-member text-center">
                                <div class="team-img">
                                <img src={Image04} alt="team member" class="img-responsive"     height='150px' width='150px'/>
                                </div>
                   
                            </div>
                            <div class="team-title text-center">
                            <div id="wrapper">  
                            <h5>د. محمد عبدالله بالبيد</h5>
                                <span>مستشار</span>
                                <br></br>
                                <a href="#open-modal3">ملف التعريف</a>
                            </div>
                            <div id="open-modal3" class="modal-window">
                <div>
                <a href="#modal-close" title="Close" class="modal-close " > <h1>&times;</h1></a>
                    <div class="text-right">
                              <h5>المؤهلات العلمية</h5>
                              <ul>
                              <li>بكالوريس في هندسة نظم التصنيع من جامعة واروك في بريطانيا</li>
                                        <li>ماجستير في الادارة الهندسية من جامعة واروك في بريطانيا</li>
                                        <li> دكتوراه في الادارة الهندسية (إدارة الجودة باستخدام إدارة المعرفة والذكاء الصناعي) من جامعة مانشستر في بريطانيا.</li>
                                  </ul>
                             <h5>الخبرات العملية</h5>
                             <ul>
                             <li> وكيل كلية الامير سلطان للسياحة و الادارة</li>
                                        <li> استاذ مشارك في قسم الهندسة الصناعية بجامعة الملك عبدالعزيز</li>
                                        <li> وكيل كلية المجتمع للتطوير بجامعة الملك عبدالعزيز</li>
                                        <li>  نائب المشرف العام على مسرعة الاعمال بجامعة الملك عبدالعزيز بالتعاون مع بايسون العالمية</li>
                                        </ul>
                             <h5>الخبرات الاستشارية</h5>
                             <ul>
                             <li>ريادة الاعمال التقنية</li>
                                        <li>ادارة الجودة</li>
                                        <li>الادارة الهندسية</li>
                                        <li>تخطيط المنشآت </li>
                                        </ul>    
                                        </div>
 </div>
  </div>



                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4">
                            <div class="team-member text-center">
                                <div class="team-img">
                                <img src={Image07} alt="team member" class="img-responsive"     height='150px' width='150px'/>
                                </div>
                       
                            </div>
                            <div class="team-title text-center">
                            <div id="wrapper">  
                                <h5>م. متعب صاهود المطيري</h5>
                                <span>مستشار  </span>
                                <br></br>
                                <a href="#open-modal5">ملف التعريف</a>
                            </div>

                            <div id="open-modal5" class="modal-window">
                <div>
                <a href="#modal-close" title="Close" class="modal-close " > <h1>&times;</h1></a>
                    <div class="text-right">
                              <h5>المؤهلات العلمية</h5>
                              <ul>
                              <li>بكالوريوس في الهندسة الكهربائية – نظام الاتصالات - من جامعة الملك فهد للبترول والمعادن.</li> 
                                  </ul>
                             <h5>الخبرات العملية</h5>
                             <ul>
                             <li>مدير دائرة الاتصالات وتقنية المعلومات بوزارة الداخلية بالعاصمة المقدسة.</li>
                             <li>مساعد مدير ادارة الاتصالات وتقنية المعلومات بوزارة الداخلية بمنطقة مكة المكرمة.</li>
                             <li>مدير ادارة الاتصالات وتقنية المعلومات بوزارة الداخلية بمنطقة مكة المكرمة.</li>
                                       
                                        </ul>
                             <h5>الخبرات الاستشارية</h5>
                             <ul>
                             <li>إدارة المشاريع</li>
                             <li>الادارة الاستراتيجية</li>
                             <li>مؤشرات قياس الأداء</li>
                             <li>الادارة الهندسية</li>
                                        </ul>    
                                        </div>
 </div>
 </div>  </div>



                        </div>
                        <div class="col-md-4 col-sm-4">
                            <div class="team-member text-center">
                                <div class="team-img">
                                <img src={Image05} alt="team member" class="img-responsive"   height='150px' width='150px'/>
                                </div>
           
                            </div>
                            <div class="team-title text-center" >
                            <div id="wrapper">  
                            <h5>م.فارس هاني التركي</h5>
                                <span>مستشار</span>
                                <br></br>
                                <a href="#open-modal7">ملف التعريف</a>
                            </div>

                            <div id="open-modal7" class="modal-window">
                <div>
                <a href="#modal-close" title="Close" class="modal-close " >  <h1>&times;</h1></a>
                    <div class="text-right">
                              <h5>المؤهلات العلمية</h5>
                              <ul>
                              <li>بكالوريس في الهندسة الصناعية من جامعة الملك عبدالعزيز</li>
                                        <li>ماجستير في ادارة الاعمال من جامعة لندن كلية كوين ماري</li>
                                        <li>دبلوم في القياد من جامعة لندن للاعمال ببريطانيا</li>
                                  </ul>
                             <h5>الخبرات العملية</h5>
                             <ul>
                             <li> مساعد تنفيذي في البنك الاهلي التجاري</li>
                                        <li> مدير برنامج الولاء و الحفاظ على العملاء في البنك الاهلي التجاري</li>
                                        <li> مدير وحدة الولاء في البنك الاهلي التجاري</li>
                                        <li>مدير الشراكات وتطوير المبيعات في البنك الأهلي التجاري.</li>
                                         <li>  المؤسس و المدير العام لمطاعم فطور فارس</li>
                                        <li>شريك ومؤسس في مقهى قطرات وتطبيق صبار للتوظيف وشركة حركة للترفيه</li>
                                        <li>رئيس لجنة شباب الاعمال بالغرفة التجارية بجدة</li>


                                        </ul>
                             <h5>الخبرات الاستشارية</h5>
                             <ul>
                             <li>ريادة الاعمال </li>
                                        <li>بناء العلامة التجارية</li>
                                        <li>الادارة الاستراتيجية</li>
                                        <li>استراتيجيات التسويق</li>
                                        </ul>    
                                        </div>
 </div>
  </div>

                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4">
                            <div class="team-member text-center">
                                <div class="team-img">
                                <img src={Image06} alt="team member" class="img-responsive"     height='150px' width='150px'/>
                                </div>
                 
                            </div>
                            <div class="team-title text-center">
                            <div id="wrapper">  
                            <h5>د. عبدالله عمر الرابغي </h5>
                                <span>مستشار</span>
                                <br></br>
                                <a href="#open-modal4">ملف التعريف</a>
                            </div>

                            <div id="open-modal4" class="modal-window">
                <div>
                <a href="#modal-close" title="Close" class="modal-close " >  <h1>&times;</h1></a>
                    <div class="text-right">
                              <h5>المؤهلات العلمية</h5>
                              <ul>
                              
                              <li>بكالوريس في هندسة النظم الصناعية من جامعة الملك عبدالعزيز</li>
                                        <li>دبلوم في ادارة الموارد البشرية من اكاديمية التعلم الاوروبية ببريطانيا</li>
                                        <li>ماجستير في الادارة الهندسية من جامعة واروك في بريطانيا</li>
                                        <li>دكتوراه في الادارة الصناعية من جامعة كرانفيد في بريطانيا</li>
                                  </ul>
                             <h5>الخبرات العملية</h5>
                             <ul>
                                         <li> اخصائي موارد بشرية في امانة جدة</li>
                                        <li> محلل اعمال في مجموعة بناء في جدة</li>
                                        <li> مدير المشاريع في مجموعة بناء في جدة</li>
                                        <li>  استاذ مساعد في قسم الهندسة الصناعية بجامعة جدة</li>
                                        <li>رئيس بيت الخبرة لمركز منارات للتدريب والاستشارات بجامعة جدة.</li>
                                        <li>مستشار برنامج خدمة ضيوف الرحمن (أحد البرامج التنفيذية لرؤية المملكة 2030</li>
                                        <li>مستشار معهد خادم الحرمين الشريفين لأبحاث الحج والعمرة والزيارة بجامعة أم القرى</li>
                                        </ul>
                             <h5>الخبرات الاستشارية</h5>
                             <ul>
                             <li>استراتيجية العمليات </li>
                                        <li>التخطيط التنفيذي </li>
                                        <li>ادارة المشاريع</li>
                                        <li>استخدام المحاكاة لتحليل و تطوير العمليات</li>
                                         <li>التخطيط التحكم في الانتاج</li>
                                        </ul>    
                                        </div>
 </div>
 </div>  </div>



                        </div>


                        <div class="col-md-4 col-sm-4">
                            <div class="team-member text-center">
                                <div class="team-img">
                                <img src={Image08} alt="team member" class="img-responsive"    height='150px' width='150px'/>
                                </div>
              
                            </div>
                            <div class="team-title text-center">
                            <div id="wrapper">  
                                <h5>م. ريان منير السليماني</h5>
                                <span>مستشار</span>
                                <br></br>
                                <a href="#open-modal13">ملف التعريف</a>
                            </div>

                            <div id="open-modal13" class="modal-window">
                <div>
                <a href="#modal-close" title="Close" class="modal-close " >  <h1>&times;</h1></a>
                    <div class="text-right">
                              <h5>المؤهلات العلمية</h5>
                              <ul>
                                  <li>بكالوريوس في الهندسة والعمارة الاسلامية من جامعة أم القرى.</li> 
                                  </ul>
                             <h5>الخبرات العملية</h5>
                             <ul>
                             <li>مهندس معماري بشركة علوان التجارية.</li>
                             <li>مدير موقع بمجموعة الفيصلية.</li>
                             <li>مدير المشاريع بالشركة العربية الطبية – نواه.</li>
                             <li>مدير المشاريع بمجموعة الفيصلية.</li>
                                        </ul>
                             <h5>الخبرات الاستشارية</h5>
                             <ul>
                                        <li>إدارة المشاريع</li>
                                        <li>ادارة المخاطر</li>
                                        <li>التصاميم المعمارية</li>
                                        <li>التصميم الداخلي</li>
                               
                                        </ul>    
                                        </div>
 </div>
 </div>  </div>
                        </div>


                        <div class="col-md-4 col-sm-4">
                            <div class="team-member text-center">
                                <div class="team-img">
                                <img src={Image09} alt="team member" class="img-responsive"     height='150px' width='150px'/>
                                </div>
                 
                            </div>
                            <div class="team-title text-center">
                            <div id="wrapper"> 
                                <h5>م. عماد عبدالرزاق وعظ الدين </h5>
                                <span>مستشار</span>
                                <br></br>
                                <a href="#open-modal12">ملف التعريف</a>
                            </div>


                            <div id="open-modal12" class="modal-window">
                <div>
                <a href="#modal-close" title="Close" class="modal-close " >  <h1>&times;</h1></a>
                    <div class="text-right">
                              <h5>المؤهلات العلمية</h5>
                              <ul>
                                  <li>بكالوريوس في الهندسة المدنية من جامعة أم القرى</li>
                                    </ul>
                             <h5>الخبرات العملية</h5>
                             <ul>
                                        <li>مهندس مدني بالإدارة الهندسية بالهيئة الملكية للجبيل وينبع</li>
                                        <li>الرئيس التنفيذي لمجموعة عبدالرزاق وعظ الدين التجارية</li>
                                         </ul>
                             <h5>الخبرات الاستشارية</h5>
                             <ul>
                                        <li>إدارة المشاريع</li>
                                        <li>تصاميم الطرق والبنية التحتية</li>
                                        <li>الدراسات المرورية</li>
                               
                                        </ul>    
                                        </div>
 </div>
 </div>  </div>
                        </div>
                    </div>
           

                </div>
 
    </Section>
  );
};

export default Advisors;
